<template>
  <div>
    <slot :openDialog="openDialog"></slot>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title primary-title>
          <h4 class="headline logo_text">
            <i class="fa fa-map-o logo_icon" />&nbsp;Delete {{ titleToDelete }}
          </h4>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <p />
          Are you sure you want to delete this map?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="deleteMap">
            Delete
          </v-btn>
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DeleteMap",
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapGetters(["currMap", "maps"]),
    titleToDelete() {
      if (this.hasTitle) {
        return this.title;
      } else if (this.currMap) {
        return this.currMap.title;
      }

      return "";
    },
    hasTitle() {
      return this.title && this.title.length > 0;
    }
  },
  methods: {
    async deleteMap() {
      await this.$store.dispatch("deleteMapByTitle", this.titleToDelete);
      this.dialog = false;
      if (this.maps.length > 0) {
        this.$router.push(`/maps`);
      } else {
        this.$router.push("/");
      }
    },
    openDialog() {
      this.dialog = true;
    }
  }
};
</script>
